/*
 * Project: asystentka-lekarza
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 18.05.2022
 * Time: 12:44
 */

import {ReactComponent as Logo} from "../../../../assets/images/logo-asystentka-lekarza.svg";

import React from 'react';

const HomeTopbar = () => {
    return (
        <div className={'w-full grid md:flex items-center gap-1 md:gap-10 text-primary-main text-sm'}>
            <Logo />

            <div className={'px-1 md:px-5 py-2 hidden md:flex rounded hover:bg-background-dark cursor-pointer transition-all'}>
                O asystencie
            </div>

            <div className={'px-1 md:px-5 py-2 hidden md:flex rounded hover:bg-background-dark cursor-pointer transition-all'}>
                Jak działa?
            </div>

            <div className={'px-1 md:px-5 py-2 hidden md:flex rounded hover:bg-background-dark cursor-pointer transition-all'}>
                Kontakt
            </div>
        </div>
    );
};

export default HomeTopbar;