import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomeView from "../modules/public/Views/HomeView/HomeView";
import LoginView from "../modules/profile/Views/LoginView/LoginView";
import RegisterView from "../modules/profile/Views/RegisterView/RegisterView";
import DashboardView from "../modules/dashboard/Views/DashboardView/DashboardView";
import SingleMaterialView from "../modules/materials/Views/SingleMaterialView/SingleMaterialView";
import SettingsView from "../modules/profile/Views/SettingsView/SettingsView";
import ForgotPasswordView from "../modules/profile/Views/ForgotPasswordView/ForgotPasswordView";
import {ROUTES_CORE, ROUTES_DASHBOARD, ROUTES_MATERIAL, ROUTES_PROFILE} from "../shared/constants/routes";
import TopupView from "../modules/profile/Views/TopupView/TopupView";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={ROUTES_CORE.HOME} element={<HomeView />} />
                <Route path={ROUTES_CORE.LOGIN} element={<LoginView />} />
                <Route path={ROUTES_CORE.REGISTER} element={<RegisterView />} />
                <Route path={ROUTES_CORE.FORGOT_PASSWORD} element={<ForgotPasswordView />} />

                <Route path={ROUTES_DASHBOARD.DASHBOARD} element={<DashboardView />} />
                <Route path={ROUTES_MATERIAL.ITEM(":id")} element={<SingleMaterialView />} />

                <Route path={ROUTES_PROFILE.SETTINGS} element={<SettingsView />} />
                <Route path={ROUTES_PROFILE.TOPUP} element={<TopupView />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
