/*
 * Project: asystentka-lekarza-front
 * Author: Dominik Obłoza
 * User: @BLOCKYe
 * Date: 23.05.2022
 * Time: 14:02
*/

import React from 'react';
import DashboardTopbar from "../../../dashboard/components/DashboardTopbar/DashboardTopbar";
import MaterialDetailTag from "../../components/MaterialDetailTag/MaterialDetailTag";
import {MdOutlineAccessTime} from "react-icons/md";
import {BsPerson} from 'react-icons/bs';
import {Divider} from "@chakra-ui/react";
import MaterialStep from "../../components/MaterialStep/MaterialStep";
import MaterialCard from "../../../dashboard/components/MaterialCard/MaterialCard";
import MaterialFile from "../../components/MaterialFile/MaterialFile";
import Footer from "../../../../shared/components/Footer/Footer";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";

const SingleMaterialView = () => {
    return (
        <div>
            <DashboardTopbar/>

            {/* <--- Content ---> */}
            <div className={'grid place-items-center text-tertiary-main'}>
                <div className={'w-full max-w-7xl p-3 pt-10 md:p-10'}>

                    {/* <--- Header ---> */}
                    <div className="grid lg:flex items-center justify-between w-full gap-3 lg:gap-10">
                        <div>
                            <div className="text-xl md:text-2xl font-bold sm:whitespace-nowrap">
                                Tytuł materiału
                            </div>

                            <div className="text-md text-tertiary-light mt-2">
                                Krótki podtytuł materiału
                            </div>

                            <div className="text-sm max-w-2xl mt-2">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet quibusdam, velit!
                                Aliquid asperiores doloremque doloribus, enim eum expedita harum, nemo placeat porro
                                praesentium rem repellat rerum saepe suscipit tempora, voluptatem!
                            </div>
                        </div>

                        {/* <--- Details tags ---> */}
                        <div className="flex items-center gap-5 flex-wrap mt-5 lg:mt-0">
                            <MaterialDetailTag label={'Długość materiału'} text={'45 minut'}
                                icon={<MdOutlineAccessTime/>}/>

                            <MaterialDetailTag label={'Autor materiałów'} text={'dr. Jan Matejko'}
                                icon={<BsPerson/>}/>
                        </div>
                    </div>

                    <div className="mt-10">
                        <Divider/>
                    </div>

                    {/* <--- Steps with materials ---> */}
                    <div className={'mt-10'}>
                        <div>
                            <MaterialDetailTag label={'Krok pierwszy'} text={'Zapoznaj się z materiałem'}
                                icon={'1'}/>
                            <MaterialStep>
                                <VideoPlayer
                                    src={'https://player.vimeo.com/video/712851640?h=9897590d86&badge=0&autopause=0&player_id=0&app_id=58479'}/>
                            </MaterialStep>
                        </div>

                        <div className={'mt-3'}>
                            <MaterialDetailTag label={'Krok drugi'} text={'Rozwiąż zadania pomocnicze'}
                                icon={'2'}/>
                            <MaterialStep>
                                <div className="grid lg:grid-cols-2 gap-3">
                                    <MaterialFile/>
                                    <MaterialFile/>
                                    <MaterialFile/>
                                    <MaterialFile/>
                                </div>
                            </MaterialStep>
                        </div>


                        <div className={'mt-3'}>
                            <MaterialDetailTag label={'Krok trzeci'} text={'Obejrzyj podsumowanie'}
                                icon={'3'}/>
                            <MaterialStep>
                                <VideoPlayer
                                    src={'https://player.vimeo.com/video/712851640?h=9897590d86&badge=0&autopause=0&player_id=0&app_id=58479'}/>
                            </MaterialStep>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default SingleMaterialView;
