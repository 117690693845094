/*
 * Project: asystentka-lekarza
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 27.05.2022
 * Time: 10:20
 */

import React from 'react';
import DashboardTopbar from "../../../dashboard/components/DashboardTopbar/DashboardTopbar";
import {Checkbox, Input} from "@chakra-ui/react";
import Button from "../../../../shared/components/Button/Button";
import {Link} from "react-router-dom";

const SettingsView = () => {
    return (
        <div>
            <DashboardTopbar/>

            {/* <--- Content ---> */}
            <div className={'grid place-items-center'}>
                <div className={'w-full max-w-3xl p-3 pt-10 md:p-10'}>

                    <div className="text-xl md:text-2xl font-bold sm:whitespace-nowrap">
                        Ustawienia konta
                    </div>


                    <div className="text-sm text-tertiary-light mt-5">
                        Dane personalne
                    </div>

                    <div className="grid md:grid-cols-2 gap-3 mt-3">
                        <Input placeholder={'Imię'}/>
                        <Input placeholder={'Nazwisko'}/>
                        <Input placeholder={'Tytuł naukowy'}/>
                        <Checkbox>
                            <div className={'text-sm'}>
                                Dodaj mój podpis do każdej wysyłki
                            </div>
                        </Checkbox>
                    </div>


                    <div className="text-sm text-tertiary-light mt-10">
                        Dane logowania
                    </div>

                    <div className="grid md:grid-cols-2 gap-3 mt-3">
                        <Input placeholder={'Aktualne hasło'} type={'password'}/>
                        <Input placeholder={'Nowe hasło'} type={'password'}/>
                        <Input placeholder={'Adres e-mail'} type={'email'}/>
                    </div>


                    <div className={'mt-10 flex justify-end items-center gap-3'}>
                        <Link to={'/dashboard'}>
                            <Button text={'Anuluj'} variant={'OUTLINED'} color={'PRIMARY'}/>
                        </Link>
                        <Button text={'Zapisz zmiany'} variant={'SOLID'} color={'PRIMARY'}/>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SettingsView;