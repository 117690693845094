/*
 * Project: asystentka-lekarza-front
 * Author: Dominik Obłoza
 * User: @BLOCKYe
 * Date: 19.05.2022
 * Time: 14:01
*/

import React, {useEffect, useState} from 'react';
import DashboardTopbar from "../../components/DashboardTopbar/DashboardTopbar";
import MaterialCard from "../../components/MaterialCard/MaterialCard";
import {
    Checkbox,
    Input,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
    Textarea,
    useDisclosure
} from "@chakra-ui/react";
import Button from "../../../../shared/components/Button/Button";
import FloatingButton from "../../../../shared/components/Button/FloatingButton";
import CategoryItem from "../../components/CategoryItem/CategoryItem";
import {MdSendToMobile} from 'react-icons/md';
import Footer from "../../../../shared/components/Footer/Footer";

const DashboardView = () => {
    // Actual selected method for sending materials
    const [selectedMethod, setSelectedMethod] = useState<'SMS' | 'MAIL'>('SMS')
    const [inputContactPlaceholder, setInputContactPlaceholder] = useState('')

    // Modal controller hooks
    const {isOpen, onOpen, onClose} = useDisclosure()


    /**
     * This function is used to
     * control input placeholder
     */

    const changeInputContactPlaceholder = () => {
        if (selectedMethod === 'SMS') setInputContactPlaceholder('Numer telefonu pacjenta');
        if (selectedMethod === 'MAIL') setInputContactPlaceholder('Adres email pacjenta')
    }

    useEffect(() => {
        changeInputContactPlaceholder()
    }, [selectedMethod])

    return (
        <div>
            <DashboardTopbar />

            {/* <--- Content ---> */}
            <div className={'grid justify-center'}>

                <div className={'w-full max-w-7xl p-3 pt-10 md:p-10 md:pb-48'}>

                    <div className="grid sm:flex items-center gap-3 sm:gap-10 font-bold sm:whitespace-nowrap">
                        <div className="text-xl md:text-2xl text-tertiary-main">
                            Dostępne zasoby
                        </div>

                        <Input placeholder={'Wyszukaj...'} />
                    </div>


                    {/* <--- Categories list ---> */}
                    <div className={'flex gap-5 flex-wrap mt-5'}>
                        <CategoryItem text={'Wszystkie (30)'} selected={true} />
                        <CategoryItem text={'Materiały (21)'} selected={false} />
                        <CategoryItem text={'Produkty (9)'} selected={false} />
                    </div>

                    <div className="text-sm text-tertiary-light mt-5">
                        Zanacz zasoby, które wyślesz pacjentowi.
                    </div>

                    {/* <--- All materials grid ---> */}
                    <div className="grid gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-5">
                        <MaterialCard />
                        <MaterialCard />
                        <MaterialCard />
                        <MaterialCard />
                        <MaterialCard />
                        <MaterialCard />
                        <MaterialCard />
                        <MaterialCard />
                        <MaterialCard />
                        <MaterialCard />
                    </div>


                    {/* <--- Modal with form ---> */}
                    <>
                        <FloatingButton onClick={onOpen}>
                            <div className={'flex gap-3 items-center flex-nowrap'}>
                                <MdSendToMobile />
                                Wyślij
                            </div>
                        </FloatingButton>

                        <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent className={'!w-full md:!min-w-[600px]'}>
                                <ModalHeader>Wyślij materiały</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <div className={'grid gap-3 content-start md:px-10 w-full'}>
                                        <div className={'flex items-center justify-center'}>
                                            <Button text={'SMS'}
                                                variant={selectedMethod === 'SMS' ? 'SOLID' : 'OUTLINED'}
                                                color={'SECONDARY'} onClick={() => setSelectedMethod('SMS')} />
                                            <Button text={'EMAIL'}
                                                variant={selectedMethod === 'MAIL' ? 'SOLID' : 'OUTLINED'}
                                                color={'SECONDARY'} onClick={() => setSelectedMethod('MAIL')} />
                                        </div>

                                        <div className="text-sm">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aliquam
                                            aliquid atque,
                                            commodi consequatur deleniti dolorum eaque eius ex exercitationem explicabo
                                            facilis.
                                        </div>

                                        <Input placeholder={inputContactPlaceholder} type={'tel'} />

                                        <Textarea placeholder={'Treść wiadomości...'} />

                                        <Input placeholder={'Podpis'} type={'text'} />

                                        {/* <--- Agreements ---> */}
                                        <div className={'grid gap-5 mt-5'}>
                                            <Checkbox className={'grid gap-3'}>
                                                <div className={'text-sm text-tertiary-light'}>
                                                    *Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus,
                                                    numquam?
                                                </div>
                                            </Checkbox>
                                            <Checkbox className={'grid gap-3'}>
                                                <div className={'text-sm text-tertiary-light'}>
                                                    *Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo
                                                    laudantium
                                                    nemo nisi sint totam vel! Aliquam eius fugit laboriosam maxime
                                                    mollitia non
                                                    obcaecati, porro recusandae sequi sit. Odit, quos, voluptatibus.
                                                </div>
                                            </Checkbox>
                                            <Checkbox className={'grid gap-3'}>
                                                <div className={'text-sm text-tertiary-light'}>
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias
                                                    corporis libero
                                                    minus neque omnis perspiciatis possimus quasi totam ut voluptatum?
                                                </div>
                                            </Checkbox>
                                        </div>

                                    </div>
                                </ModalBody>

                                <ModalFooter>
                                    <Button onClick={onClose} color={'SECONDARY'} variant={'SOLID'} text={'Wyślij'} />
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </>

                </div>
            </div>


            <Footer/>
        </div>
    );
};

export default DashboardView;
