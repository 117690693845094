/*
 * Project: asystentka-lekarza-front
 * Author: Dominik Obłoza
 * User: @BLOCKYe
 * Date: 25.05.2022
 * Time: 11:31
*/

import React from 'react';

interface ICategoryItemProps {
    text: string
    selected: boolean
}

const CategoryItem: React.FC<ICategoryItemProps> = (props) => {

    /**
     * This function is used to
     * return view of element
     * depending on selection
     * @param isSelected
     */

    const isSelectedFactory = (isSelected: boolean) => {
        if (isSelected) return 'text-sm py-2 px-5 bg-background-extraDark text-tertiary-main rounded-md transition-all cursor-pointer'
        else return 'text-sm py-2 px-5 text-tertiary-light rounded-md transition-all hover:bg-background-dark cursor-pointer'
    }

    return (
        <div className={isSelectedFactory(props.selected)}>
            {props.text}
        </div>
    );
};

export default CategoryItem;
