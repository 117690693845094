/*
 * Project: asystentka-lekarza
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 27.05.2022
 * Time: 13:50
 */

import React from 'react';
import {ReactComponent as Logo} from "../../../../assets/images/logo-asystentka-lekarza.svg";
import {Input} from "@chakra-ui/react";
import Button from "../../../../shared/components/Button/Button";
import {Link, useNavigate} from "react-router-dom";
import {ROUTES_CORE} from "../../../../shared/constants/routes";

const ForgotPasswordView = () => {
    let navigate = useNavigate();

    const submitLogin = (e: React.FormEvent) => {
        e.preventDefault();

    }

    return (
        <div className={'grid min-h-screen place-items-center content-center'}>
            <div className={'w-full max-w-md p-5 md:p-10 rounded-md md:border-[1px]'}>
                <Link className={'grid justify-center text-primary-main'} to={'/'}>
                    <Logo/>
                </Link>

                <div className="mt-5 text-tertiary-light text-center">
                    Przypomnij hasło
                </div>

                <form className={'grid gap-5 mt-5'} onSubmit={submitLogin}>
                    <Input placeholder={'Adres e-mail'} type={'email'}/>

                    <div className="flex gap-3 justify-end items-center">
                        <Button text={'Zresetuj hasło'} variant={'SOLID'} color={'PRIMARY'} type={'submit'}/>
                    </div>
                </form>
            </div>

            <div className={'w-full max-w-md p-5 md:px-10 transform bg-background-dark rounded-md ' +
                'border-primary-light mt-5 flex gap-3 justify-between items-center'}>
                <div className={'text-sm'}>
                    Posiadasz już konto?
                </div>
                <Link to={ROUTES_CORE.LOGIN}>
                    <Button text={'Zaloguj się'} variant={'OUTLINED'} color={'PRIMARY'}/>
                </Link>
            </div>
        </div>
    );
};

export default ForgotPasswordView;
