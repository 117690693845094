/*
 * Project: asystentka-lekarza-front
 * Author: Dominik Obłoza
 * User: @BLOCKYe
 * Date: 25.05.2022
 * Time: 13:10
*/

import React from 'react';

interface IMaterialDetailLabelProps {
    label: string,
    text: string,
    icon: React.ReactNode | string;
}

const MaterialDetailTag: React.FC<IMaterialDetailLabelProps> = (props) => {

    const renderIconType = (type: any) => {
        if(typeof type === "string") return ' text-center min-w-[45px] leading-[45px]'
        else return ' p-2'
    }

    return (
        <div className={'flex gap-3 items-center'}>
            <div className={"bg-background-extraDark rounded-full text-2xl grid place-items-center text-tertiary-light font-bold" + renderIconType(props.icon)}>
                {props.icon}
            </div>

            <div>
                <div className="text-sm text-tertiary-light md:whitespace-nowrap" >
                    {props.label}
                </div>
                <div className={'text-md font-bold md:whitespace-nowrap text-tertiary-main'}>
                    {props.text}
                </div>
            </div>

        </div>
    );
};

export default MaterialDetailTag;
