/*
 * Project: asystentka-lekarza-front
 * Author: Dominik Obłoza
 * User: @BLOCKYe
 * Date: 20.05.2022
 * Time: 12:06
*/

import React, {useState} from 'react';
import {Divider, Tooltip} from "@chakra-ui/react";
import {AiOutlineFile} from 'react-icons/ai';
import {BsFileEarmarkPlay} from 'react-icons/bs';
import {MdOutlineAccessTime, MdRadioButtonChecked, MdRadioButtonUnchecked} from "react-icons/md";
import {Link} from "react-router-dom";
import {FaLongArrowAltRight} from "react-icons/fa";
import {ROUTES_MATERIAL} from "../../../../shared/constants/routes";

const MaterialCard = () => {

    const [isSelected, setIsSelected] = useState<boolean>(false)

    return (
        <div className={`w-full h-full relative overflow-hidden border-2 rounded-md grid gap-1 transition-all
             hover:bg-background-dark ${isSelected ? 'border-primary-light bg-background-dark opacity-100 text-primary-main' : 'opacity-60 text-tertiary-main'} 
             hover:opacity-100`}>

            <img className={'w-full aspect-video cursor-pointer object-cover'}
                onClick={() => setIsSelected(!isSelected)}
                src={'https://images.unsplash.com/photo-1638202993928-7267aad84c31?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGRvY3RvcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500'}/>

            {/* <--- Content ---> */}
            <div className={'grid gap-1 p-3 md:p-5 items-center content-center w-full h-full'}>

                <div className="font-bold text-lg">
                    Stawy ręki
                </div>

                <div className="text-md text-tertiary-light">
                    Problematyka statów ręki
                </div>

                <div className="text-sm">
                    Materiały pomagają zrozumieć pacjentowi problem oraz podpowiadają jak sobie z nim radzić.
                </div>

                <div className="text-sm text-tertiary-light">
                    ks. Jan Mickiewicz
                </div>

                <Divider className={'mt-3'}/>

                <div className={'flex items-center justify-between gap-1 mt-3 flex-wrap'}>
                    <Tooltip label={'Ilość filmów'}>
                        <div className={'flex items-center gap-1 text-sm text-tertiary-light whitespace-nowrap ' +
                            'p-2 transition-all hover:bg-background-extraDark rounded-md'}>
                            <BsFileEarmarkPlay/>
                            4
                        </div>
                    </Tooltip>

                    <Tooltip label={'Ilość materiałów'}>
                        <div className={'flex items-center gap-1 text-sm text-tertiary-light whitespace-nowrap ' +
                            'p-2 transition-all hover:bg-background-extraDark rounded-md'}>
                            <AiOutlineFile/>
                            6
                        </div>
                    </Tooltip>

                    <Tooltip label={'Czas potrzebny na zapoznanie się z materiałem'}>
                        <div className={'flex items-center gap-1 text-sm text-tertiary-light whitespace-nowrap ' +
                            'p-2 transition-all hover:bg-background-extraDark rounded-md'}>
                            <MdOutlineAccessTime/>
                            66 min
                        </div>
                    </Tooltip>

                    <Tooltip label={'Przejdź do materiału'}>
                        <Link to={ROUTES_MATERIAL.ITEM('example')} target="_blank" rel="noopener noreferrer">
                            <div className={'text-tertiary-light cursor-pointer p-2 rounded-full transition-all ' +
                                'hover:hover:bg-background-extraDark hover:text-secondary-main'}>
                                <FaLongArrowAltRight/>
                            </div>
                        </Link>
                    </Tooltip>
                </div>
            </div>

            {isSelected && (
                <Tooltip label={'Odznacz materiał'}>
                    <div className="z-10 absolute rounded-full top-3 text-center right-3 bg-primary-main p-2 text-white
                font-bold flex items-center justify-center border-2 border-background-main cursor-pointer"
                        onClick={() => setIsSelected(!isSelected)}>
                        <MdRadioButtonChecked/>
                    </div>
                </Tooltip>
            )}

            {!isSelected && (
                <Tooltip label={'Zaznacz materiał'}>
                    <div className="z-10 absolute rounded-full top-3 text-center right-3 bg-background-main p-2 text-white
                font-bold flex items-center justify-center border-2 border-primary-light cursor-pointer"
                        onClick={() => setIsSelected(!isSelected)}>
                        <MdRadioButtonUnchecked/>
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default MaterialCard;
