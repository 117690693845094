/*
 * Project: asystentka-lekarza
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 30.05.2022
 * Time: 13:12
 */

import React from 'react';

interface IPropsVideoPlayer {
    src: string
}

const VideoPlayer: React.FC<IPropsVideoPlayer> = (props) => {
    return (
        <div className={'overflow-hidden rounded-md border-2'}>
            <iframe className={'w-full h-full aspect-video rounded-xl;'}
                src={props.src} frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>
        </div>
    );
};

export default VideoPlayer;