/*
 * Project: asystentka-lekarza-front
 * Author: Dominik Obłoza
 * User: @BLOCKYe
 * Date: 25.05.2022
 * Time: 11:17
*/

import React from 'react';

interface IFloatingButtonProps {
    children: JSX.Element[] | JSX.Element;
    onClick: () => void;
}

const FloatingButton: React.FC<IFloatingButtonProps> = (props) => {
    return (
        <div onClick={props.onClick} className={'flex fixed bottom-5 md:bottom-10 right-5 md:right-10 text-background-main rounded-md ' +
            'custom-shadow text-lg font-bold bg-secondary-main px-10 py-3 rounded-md ' +
            'cursor-pointer transition-all hover:bg-secondary-dark'}>
            {props.children}
        </div>

    );
};

export default FloatingButton;
