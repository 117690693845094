/*
 * Project: asystentka-lekarza-front
 * Author: Dominik Obłoza
 * User: @BLOCKYe
 * Date: 19.05.2022
 * Time: 12:20
*/

import React from 'react';
import {ReactComponent as Logo} from "../../../../assets/images/logo-asystentka-lekarza.svg";
import {Checkbox, Input, Select} from "@chakra-ui/react";
import Button from "../../../../shared/components/Button/Button";
import {Link} from "react-router-dom";

const RegisterView = () => {
    return (
        <div className={'grid min-h-screen place-items-center content-center'}>
            <div className={'w-full max-w-2xl p-5 py-20 md:p-10 rounded-md md:border-[1px]'}>
                <Link className={'grid justify-center text-secondary-main'} to={'/'}>
                    <Logo />
                </Link>

                <div className="mt-5 text-tertiary-light text-center">
                    Zarejestruj konto
                </div>

                <form className={'grid gap-5 mt-5'}>
                    <div className={'grid md:grid-cols-2 gap-5'}>
                        <Input placeholder={'Imię'} type={'text'} />
                        <Input placeholder={'Nazwisko'} type={'text'} />
                    </div>
                    <div className={'grid md:grid-cols-2 gap-5'}>
                        <Input placeholder={'Adres e-mail'} type={'email'} />
                        <Input placeholder={'Kod zaproszenia'} type={'text'} />
                    </div>
                    <div className={'grid md:grid-cols-2 gap-5'}>
                        <Input placeholder={'Numer PWZ'} type={'number'} />
                        <Input placeholder={'Data urodzenia'} type={'date'} className={'text-[#9CA3AF]'}/>
                    </div>
                    <div className={'grid md:grid-cols-2 gap-5'}>
                        <Select placeholder='Wybierz specjalizacje z listy' className={'text-[#9CA3AF]'}>
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option3'>Option 3</option>
                        </Select>
                        <Input placeholder={'Utwórz hasło'} type={'password'} />
                    </div>

                    {/* <--- Agreements ---> */}
                    <div className={'grid gap-5 mt-5'}>
                        <Checkbox className={'grid gap-3'}>
                            <div className={'text-sm text-tertiary-light'}>
                                *Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, numquam?
                            </div>
                        </Checkbox>
                        <Checkbox className={'grid gap-3'}>
                            <div className={'text-sm text-tertiary-light'}>
                                *Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo laudantium nemo nisi sint totam vel! Aliquam eius fugit laboriosam maxime mollitia non obcaecati, porro recusandae sequi sit. Odit, quos, voluptatibus.
                            </div>
                        </Checkbox>
                        <Checkbox className={'grid gap-3'}>
                            <div className={'text-sm text-tertiary-light'}>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias corporis libero minus neque omnis perspiciatis possimus quasi totam ut voluptatum?
                            </div>
                        </Checkbox>
                    </div>

                    <div className="flex gap-3 justify-end items-center">
                        <Button text={'Zarejestruj się'} variant={'SOLID'} color={'SECONDARY'} type={'submit'} />
                    </div>
                </form>
            </div>

            <div className={'w-full max-w-2xl p-5 md:px-10 transform bg-background-dark rounded-md ' +
                'border-primary-light mt-5 flex gap-3 justify-between items-center'}>
                <div className={'text-sm'}>
                    Masz już konto?
                </div>
                <Link to={'/login'}>
                    <Button text={'Zaloguj się'} variant={'OUTLINED'} color={'PRIMARY'} />
                </Link>
            </div>
        </div>
    );
};

export default RegisterView;
