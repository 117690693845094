/*
 * Project: asystentka-lekarza
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 18.05.2022
 * Time: 13:10
 */

import React from 'react';

interface IButtonProps {
    text: string
    variant: IVariants
    color: IColors
    type?: any
    onClick?: () => void;
}

type IVariants = 'SOLID' | 'OUTLINED' | 'GHOST'

type IColors = 'PRIMARY' | 'SECONDARY'

const Button: React.FC<IButtonProps> = (props) => {


    /**
     * This function is used to
     * render button based on type
     * @param type
     * @param color
     */

    const renderType = (type: IVariants, color: IColors) => {
        switch (type) {
            case 'SOLID': {
                if (color === 'SECONDARY') return 'border-[1px] border-secondary-main bg-secondary-main py-2 px-5 text-white rounded transition-all hover:bg-secondary-dark'
                else return 'border-[1px] border-primary-main bg-primary-main py-2 px-5 text-white rounded transition-all hover:bg-primary-dark'
            }

            case "OUTLINED": {
                if (color === 'SECONDARY') return 'border-[1px] border-secondary-main py-2 px-5 text-secondary-main rounded transition-all hover:bg-[#fff1f2]'
                else return 'border-[1px] border-primary-main py-2 px-5 text-primary-main rounded transition-all hover:bg-background-dark'
            }

            case "GHOST": {
                if (color === 'SECONDARY') return 'py-2 px-5 text-secondary-main rounded transition-all hover:bg-[#fff1f2]'
                else return 'py-2 px-5 text-primary-main rounded transition-all hover:bg-background-dark'
            }

            default: {
                return 'py-2 px-5 text-secondary-main rounded transition-all hover:bg-[#fff1f2]'
            }
        }
    }


    /**
     * This function is used to
     * handle onClick event
     */

    const onClick = () => {
        if (!props.onClick) return

        props.onClick()
    }

    return (
        <button className={renderType(props.variant, props.color) + ' text-sm'} type={props.type}
            onClick={onClick}>
            {props.text}
        </button>
    )
};

export default Button;