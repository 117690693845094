/*
 * Project: asystentka-lekarza-front
 * Author: Dominik Obłoza
 * User: @BLOCKYe
 * Date: 26.05.2022
 * Time: 09:14
*/

import React from 'react';
import MaterialCard from "../../../dashboard/components/MaterialCard/MaterialCard";

interface IPropsMaterialStep {
    children: JSX.Element[] | JSX.Element;
}

const MaterialStep: React.FC<IPropsMaterialStep> = (props) => {
    return (
        <div className={'border-l-4 border-background-extraDark mt-3 ml-5 p-5 pl-5'}>
            {props.children}
        </div>
    );
};

export default MaterialStep;
