import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import App from './core/App';
import {ChakraProvider} from "@chakra-ui/react";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ChakraProvider>
            <App/>
        </ChakraProvider>
    </React.StrictMode>
);

