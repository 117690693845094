/*
 * Project: asystentka-lekarza-front
 * Author: Dominik Obłoza
 * User: @BLOCKYe
 * Date: 19.05.2022
 * Time: 14:10
*/

import React from 'react';
import {Badge, IconButton, Menu, MenuButton, MenuItem, MenuList, Progress, Tooltip} from "@chakra-ui/react";
import {HiOutlineMenuAlt3} from 'react-icons/hi'
import {ReactComponent as Logo} from "../../../../assets/images/logo-asystentka-lekarza.svg";
import {Link} from "react-router-dom";
import {BsPlus} from 'react-icons/bs'
import {AiOutlineUser, AiOutlineLogout} from 'react-icons/ai'
import {ROUTES_CORE, ROUTES_PROFILE} from '../../../../shared/constants/routes';

const DashboardTopbar = () => {
    return (
        <div className={'w-full bg-background-extraDark grid place-items-center'}>
            <div className={'p-5 md:px-10 max-w-7xl grid gap-5 md:flex md:justify-between items-center w-full'}>
                <Link to={'/dashboard'}>
                    <Logo className={'text-tertiary-light w-full'}/>
                </Link>

                <div className="flex items-center gap-5 text-sm justify-center md:justify-end w-full flex-wrap">

                    {/* <--- SMS Limit ---> */}
                    <Tooltip label={'Ilość dostępnych SMSów'}>
                        <div className="flex gap-3 items-center min-w-[150px] sm:min-w-[250px]">
                            <Progress value={32} className={'rounded-full w-full'} size={'sm'} colorScheme={'gray'}/>
                            <Badge className={'!text-tertiary-light'}>16/50</Badge>
                        </div>
                    </Tooltip>

                    {/* <--- Menu ---> */}
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            icon={<HiOutlineMenuAlt3/>}
                        />
                        <MenuList>
                            <Link to={ROUTES_PROFILE.SETTINGS}>
                                <MenuItem icon={<AiOutlineUser/>}>
                                    Mój profil
                                </MenuItem>
                            </Link>

                            <Link to={ROUTES_PROFILE.TOPUP}>
                                <MenuItem icon={<BsPlus/>}>
                                    Doładuj SMS
                                </MenuItem>
                            </Link>


                            <Link to={ROUTES_CORE.LOGIN}>
                                <MenuItem icon={<AiOutlineLogout/>}>
                                    Wyloguj się
                                </MenuItem>
                            </Link>
                        </MenuList>
                    </Menu>
                </div>
            </div>
        </div>
    );
};

export default DashboardTopbar;
