/*
 * Project: asystentka-lekarza
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 18.05.2022
 * Time: 10:57
 */

import React from 'react';
import HomeTopbar from "../../components/HomeTopbar/HomeTopbar";
import Button from "../../../../shared/components/Button/Button";
import {Link} from "react-router-dom";

const HomeView = () => {
    return (
        <div className={'grid min-h-screen justify-center'}>
            <div className={'w-full max-w-7xl p-5 md:p-10'}>
                <HomeTopbar />

                {/* <--- Main container ---> */}
                <div className="grid place-items-center md:h-4/5 mt-10 md:mt-auto">
                    <div className="grid md:grid-cols-2 place-items-center gap-10 md:h-4/5">

                        {/* <--- Left column with header ---> */}
                        <div>
                            <div className="hidden md:block text-md text-primary-main">
                                Asystentka Lekarza
                            </div>
                            <div className="pt-5 text-4xl font-bold text-tertiary-main">
                                Twoje wsparcie w leczeniu pacjenta
                            </div>
                            <div className="pt-5 text-md text-tertiary-light">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse excepturi fugiat hic
                                illum nemo optio praesentium repellat tempore? At, quae.
                            </div>

                            <div className="flex gap-1 md:gap-5 items-center pt-10 flex-wrap">
                                <Link to={'/register'}>
                                    <Button text={'Zarejestruj się'} variant={'SOLID'} color={"SECONDARY"} />
                                </Link>
                                <Link to={'/login'}>
                                    <Button text={'Zaloguj się'} variant={'OUTLINED'} color={"PRIMARY"} />
                                </Link>
                            </div>
                        </div>


                        {/* <--- Right column ---> */}
                        <div className={'w-full h-full grid grid-cols-2 gap-1 md:gap-5'}>
                            <img src={'https://images.unsplash.com/photo-1638202993928-7267aad84c31?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGRvY3RvcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500'} className={'rounded-xl bg-background-extraDark w-full h-full aspect-square object-cover'} />
                            <img src={'https://images.unsplash.com/photo-1581056771107-24ca5f033842?ixlib=rb-1.2.1&raw_url=true&q=60&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE3fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500'} className={'rounded-xl bg-background-extraDark w-full h-full aspect-square object-cover'} />
                            <img src={'https://images.unsplash.com/photo-1583947581879-41e4c88394c3?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8Nnw5NzU3NTI4fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500'} className={'rounded-xl bg-background-extraDark w-full h-full aspect-square object-cover'} />
                            <img src={'https://images.unsplash.com/photo-1536064479547-7ee40b74b807?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170'} className={'rounded-xl bg-background-extraDark w-full h-full aspect-square object-cover'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeView;