/*
 * Project: asystentka-lekarza-front
 * Author: Dominik Obłoza
 * User: @BLOCKYe
 * Date: 26.05.2022
 * Time: 13:23
*/

import React from 'react';
import {AiOutlineFile} from 'react-icons/ai';

const MaterialFile = () => {
    return (
        <div className={'p-5 grid md:flex items-center gap-2 md:gap-5 border-2 transition-all hover:bg-background-dark cursor-pointer' +
            ' rounded-md'}>
            <div className="flex items-center gap-2 md:gap-5">
                <AiOutlineFile className={'text-xl min-w-[40px]'} />

                <div className="font-bold text-md">
                    Zadania do rozwiązania
                </div>
            </div>

            <div className="text-sm">
                Materiały pomagają zrozumieć pacjentowi problem oraz podpowiadają jak sobie z nim radzić.
            </div>
        </div>
    );
};

export default MaterialFile;
