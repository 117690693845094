/*
 * Project: asystentka-lekarza-front
 * Author: Dominik Obłoza
 * User: @BLOCKYe
 * Date: 19.05.2022
 * Time: 09:59
*/

import React from 'react';
import {ReactComponent as Logo} from "../../../../assets/images/logo-asystentka-lekarza.svg";
import {Checkbox, Input} from "@chakra-ui/react";
import Button from "../../../../shared/components/Button/Button";
import {Link, useNavigate} from "react-router-dom";
import {ROUTES_CORE, ROUTES_DASHBOARD} from "../../../../shared/constants/routes";

const LoginView = () => {
    let navigate = useNavigate();

    const submitLogin = (e: React.FormEvent) => {
        e.preventDefault();

        navigate(ROUTES_DASHBOARD.DASHBOARD)
    }

    return (
        <div className={'grid min-h-screen place-items-center content-center'}>
            <div className={'w-full max-w-md p-5 md:p-10 rounded-md md:border-[1px]'}>
                <Link className={'grid justify-center text-primary-main'} to={'/'}>
                    <Logo />
                </Link>

                <div className="mt-5 text-tertiary-light text-center">
                    Zaloguj się do systemu
                </div>

                <form className={'grid gap-5 mt-5'} onSubmit={submitLogin}>
                    <Input placeholder={'Adres e-mail'} type={'email'} />
                    <Input placeholder={'Hasło'} type={'password'} />
                    <Checkbox defaultChecked>
                        <div className={'text-sm'}>
                            zapamiętaj dane logowania
                        </div>
                    </Checkbox>
                    <div className="flex gap-3 justify-between items-center">
                        <Link to={ROUTES_CORE.FORGOT_PASSWORD} className={'text-sm text-tertiary-light'}>
                            Nie pamiętam hasła
                        </Link>
                        <Button text={'Zaloguj się'} variant={'SOLID'} color={'PRIMARY'} type={'submit'} />
                    </div>
                </form>
            </div>

            <div className={'w-full max-w-md p-5 md:px-10 transform bg-background-dark rounded-md ' +
                'border-primary-light mt-5 flex gap-3 justify-between items-center'}>
                <div className={'text-sm'}>
                    Nie masz konta?
                </div>
                <Link to={ROUTES_CORE.REGISTER}>
                    <Button text={'Zarejestruj się'} variant={'OUTLINED'} color={'SECONDARY'} />
                </Link>
            </div>
        </div>
    );
};

export default LoginView;
