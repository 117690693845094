/*
 * Project: asystentka-lekarza
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 27.05.2022
 * Time: 10:20
 */

import React from 'react';
import DashboardTopbar from "../../../dashboard/components/DashboardTopbar/DashboardTopbar";
import {Badge, Input, Progress} from "@chakra-ui/react";
import Button from "../../../../shared/components/Button/Button";
import {Link} from "react-router-dom";

const TopupView = () => {
    return (
        <div>
            <DashboardTopbar/>

            {/* <--- Content ---> */}
            <div className={'grid place-items-center'}>
                <div className={'w-full max-w-3xl p-3 pt-10 md:p-10'}>

                    <div className="text-xl md:text-2xl font-bold sm:whitespace-nowrap">
                        Pakiet dostępnych SMSów
                    </div>

                    <div className="text-sm text-tertiary-light mt-5">
                        Aktualna liczba dostępnych SMSów
                    </div>

                    <div className="flex items-center gap-3 mt-3">
                        <Progress value={32} className={'rounded-full w-full'} size={'sm'} colorScheme={'gray'}/>
                        <Badge className={'!text-tertiary-light'}>16/50</Badge>
                    </div>

                    <div className="text-sm text-tertiary-light mt-5">
                        W tym miejscu możesz złożyć wniosek o dodatkowy pakiet SMSów.
                    </div>

                    <div className="grid md:grid-cols-2 gap-3 mt-3">
                        <Input placeholder={'Ilość dodatkowych SMSów'} type={'number'}/>
                    </div>

                    <div className={'mt-10 flex justify-end items-center gap-3'}>
                        <Link to={'/dashboard'}>
                            <Button text={'Anuluj'} variant={'OUTLINED'} color={'PRIMARY'}/>
                        </Link>
                        <Button text={'Doładuj'} variant={'SOLID'} color={'PRIMARY'}/>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default TopupView;