export const ROUTES_CORE = {
    HOME: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password'
};

export const ROUTES_DASHBOARD = {
    DASHBOARD: '/dashboard',
};

export const ROUTES_MATERIAL = {
    ITEM: (id: string) => `/material/${id}`,
};

export const ROUTES_PROFILE = {
    SETTINGS: '/settings',
    TOPUP: '/topup'
};