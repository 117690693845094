/*
 * Project: asystentka-lekarza
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 30.05.2022
 * Time: 09:58
 */

import React from 'react';
import {ReactComponent as LogoNV} from "../../../assets/images/logo-neo-vinci.svg";

const Footer: React.FC = () => {
    return (
        <div className={'w-full bg-background-extraDark grid place-items-center'}>
            <div className={'p-5 md:py-10 max-w-7xl grid gap-5 md:flex md:justify-between items-center w-full'}>
                <LogoNV className={'text-tertiary-light w-full'}/>
            </div>
        </div>
    );
};

export default Footer;